import { dicomJSONApis, itemsApis } from '@gleamer/apis';
import { Internal, OHIF as OHIFTypes } from '@gleamer/types';
import OHIF, {
  DicomMetadataStore,
  ServicesManager,
  pubSubServiceInterface,
} from '@ohif/core';
import { isEqual } from 'lodash';
import { formatItem } from './formatItem';
import getImageId from './getImageId';

const { fetchItemOrNextForCurrentUser, fetchItemForLabeledBy } = itemsApis;
const { fetchDicomJsonItemsPreview } = dicomJSONApis;

type DicomJSONSeries = Internal.DicomJSONSeries;
type LabelTaskApiItem = Internal.LabelTaskApiItem;
type LabelTaskItem = Internal.LabelTaskItem;
type PreviewParams = Internal.PreviewParams;
type DisplaySet = OHIFTypes.DisplaySet;

const metadataProvider = OHIF.classes.MetadataProvider;

export const DICOM_JSON_EVENTS = {
  UPDATE_CURRENT_ITEM: 'event::dicomjsonservice::update_current_item',
};

const mappings = {
  studyInstanceUid: 'StudyInstanceUID',
  patientId: 'PatientID',
};

type Fetchers = Map<number | string, Promise<LabelTaskItem> | undefined>;

function matches(criteria: { [name: string]: any }) {
  return (item: { [name: string]: any }) =>
    Object.entries(criteria).every(([field, expectedValue]) =>
      isEqual(item[field], expectedValue)
    );
}

function getPreviewKey(params: PreviewParams) {
  return Object.values(params).filter(Boolean).join('-');
}

export class DicomJSONService {
  servicesManager: ServicesManager;
  currentItems: Map<string, LabelTaskItem | undefined>;
  studyInstanceUIDs: Map<string, string[]>;
  listeners: any;
  _broadcastEvent: (event: string, payload: any) => void;
  subscribe: (
    event: string,
    handler: CallableFunction
  ) => { unsubscribe: CallableFunction };
  EVENTS: Record<string, string>;
  fetchers: Fetchers;

  public static REGISTRATION = {
    name: 'dicomJSONService',
    altName: 'DicomJSONService',
    create: ({ configuration = {}, servicesManager }) => {
      return new DicomJSONService(servicesManager);
    },
  };

  constructor(servicesManager: ServicesManager) {
    this.servicesManager = servicesManager;
    this.listeners = {};
    Object.defineProperty(this, 'EVENTS', {
      value: DICOM_JSON_EVENTS,
      writable: false,
      enumerable: true,
      configurable: false,
    });
    Object.assign(this, pubSubServiceInterface);
    this.fetchers = new Map();
    this.currentItems = new Map();
    this.studyInstanceUIDs = new Map();
  }

  onModeExit() {
    DicomMetadataStore.clear();
  }

  async fetchNextItem(taskId: number) {
    if (!this.fetchers.get(taskId)) {
      this.fetchers.set(taskId, this._fetchNextItem(taskId));
    }
    return this.fetchers.get(taskId);
  }

  private getItem(key: string) {
    return this.currentItems.get(key);
  }

  async getCurrentItem(taskId: number, itemId: string) {
    const item = this.getItem(`${taskId}-${itemId}`);
    if (itemId && item) {
      return item;
    }

    if (this.fetchers.get(taskId)) {
      return this.fetchers.get(taskId);
    }

    this.fetchers.set(
      taskId,
      this.fetchAndFormatCurrentLabeledItem(taskId, itemId)
    );
    return this.fetchers.get(taskId);
  }

  async fetchAndFormatCurrentLabeledItem(taskId: number, itemId: string) {
    const { userAuthenticationService } = this.servicesManager.services;
    const fetcher = fetchItemOrNextForCurrentUser(
      userAuthenticationService,
      taskId,
      itemId
    );

    const currentItem = await fetcher;

    const key = `${taskId}-${currentItem.id}`;
    await this._formatAndLoadItem(key, currentItem);

    this.fetchers.set(taskId, undefined);
    return this.getItem(key);
  }

  async getPreviewItem(params: PreviewParams) {
    const key = getPreviewKey(params);

    if (this.currentItems.has(key)) {
      return this.getItem(key);
    }

    if (this.fetchers.get(key)) {
      return this.fetchers.get(key);
    }

    const fetcher = this.fetchPreviewItem(params);
    this.fetchers.set(key, fetcher);
    return fetcher;
  }

  async fetchPreviewItem(params: PreviewParams) {
    const { userAuthenticationService } = this.servicesManager.services;

    const currentItem = await fetchDicomJsonItemsPreview(
      userAuthenticationService,
      params
    );

    const previewKey = getPreviewKey(params);
    await this._formatAndLoadItem(previewKey, currentItem);
    this.fetchers.set(previewKey, undefined);
    return this.getItem(previewKey);
  }

  findStudies(itemKey: string, key: string, value: any) {
    if (!this.currentItems.get(itemKey)) {
      return [];
    }

    return this.currentItems
      .get(itemKey)
      .studies.filter(matches({ [key]: value }));
  }

  getStudyInstanceUIDs(key: string) {
    if (!this.currentItems.has(key)) {
      return [];
    }

    if (!this.studyInstanceUIDs.has(key)) {
      this.studyInstanceUIDs.set(
        key,
        this.currentItems.get(key).studies.map(study => study.StudyInstanceUID)
      );
    }

    return this.studyInstanceUIDs.get(key);
  }

  queryStudies(itemKey: string, param: Record<string, any>) {
    const [key, value] = Object.entries(param)[0];
    const mappedParam = mappings[key];

    // todo: should fetch from dicomMetadataStore
    const studies = this.findStudies(itemKey, mappedParam, value);

    return studies.map(aStudy => {
      return {
        accession: aStudy.AccessionNumber,
        date: aStudy.StudyDate,
        instances: aStudy.NumInstances,
        modalities: aStudy.Modalities,
        mrn: aStudy.PatientID,
        patientName: aStudy.PatientName,
        studyInstanceUid: aStudy.StudyInstanceUID,
        NumInstances: aStudy.NumInstances,
        time: aStudy.StudyTime,
        series: aStudy.series,
      };
    });
  }

  retrieveSeriesMetadata(
    itemKey: string,
    {
      StudyInstanceUID,
      madeInClient = false,
      customSort,
    }: {
      StudyInstanceUID?: string;
      madeInClient?: boolean;
      customSort?: (series: DicomJSONSeries[]) => DicomJSONSeries[];
    } = {}
  ) {
    if (!StudyInstanceUID) {
      throw new Error(
        'Unable to query for SeriesMetadata without StudyInstanceUID'
      );
    }

    const study = this.findStudies(
      itemKey,
      'StudyInstanceUID',
      StudyInstanceUID
    )[0];
    let series: DicomJSONSeries[];

    if (customSort) {
      series = customSort(study.series);
    } else {
      series = study.series;
    }

    const seriesSummaryMetadata = series.map(series => {
      const seriesSummary = {
        StudyInstanceUID: study.StudyInstanceUID,
        ...series,
      };
      delete seriesSummary.instances;
      return seriesSummary;
    });

    // Async load series, store as retrieved
    function storeInstances(naturalizedInstances) {
      DicomMetadataStore.addInstances(naturalizedInstances, madeInClient);
    }

    DicomMetadataStore.addSeriesMetadata(seriesSummaryMetadata, madeInClient);

    function setSuccessFlag() {
      const study = DicomMetadataStore.getStudy(StudyInstanceUID);
      study.isLoaded = true;
    }

    const numberOfSeries = series.length;
    series.forEach((series, index) => {
      const instances = series.instances.map(instance => {
        const obj = {
          ...instance.metadata,
          url: instance.url,
          imageId: instance.url,
          ...series,
        };
        delete obj.instances;
        return obj;
      });

      storeInstances(instances);

      if (index === numberOfSeries - 1) {
        setSuccessFlag();
      }
    });
  }

  getImageIdsForDisplaySet(
    displaySet: DisplaySet,
    dicomJsonConfig: Record<string, any>
  ) {
    const images = displaySet.images;
    const imageIds = [];

    if (!images) {
      return imageIds;
    }

    displaySet.images.forEach(instance => {
      const NumberOfFrames = instance.NumberOfFrames;

      if (NumberOfFrames > 1) {
        for (let i = 0; i < NumberOfFrames; i++) {
          const imageId = getImageId({
            instance,
            frame: i,
            config: dicomJsonConfig,
          });
          imageIds.push(imageId);
        }
      } else {
        const imageId = getImageId({ instance, config: dicomJsonConfig });
        imageIds.push(imageId);
      }
    });

    return imageIds;
  }

  getImageIdsForInstance({ instance, frame }) {
    return getImageId({
      instance,
      frame,
    });
  }

  private async _fetchNextItem(taskId: number) {
    const { userAuthenticationService } = this.servicesManager.services;

    const currentItem = await fetchItemOrNextForCurrentUser(
      userAuthenticationService,
      taskId
    );

    const key = `${taskId}-${currentItem.id}`;
    await this._formatAndLoadItem(key, currentItem);

    this.fetchers.set(taskId, undefined);
    return this.getItem(key);
  }

  async fetchLabeledItem(
    taskId: number,
    itemId: string,
    labeledBy: string
  ): Promise<LabelTaskItem> {
    if (this.fetchers.get(taskId)) {
      return this.fetchers.get(taskId);
    }
    return this._fetchItemLabeledByUser(taskId, itemId, labeledBy);
  }

  private async refreshToken() {
    const { userAuthenticationService } = this.servicesManager.services;

    let tries = 0;

    return new Promise<void>((resolve, reject) => {
      if (userAuthenticationService.getUser()) {
        return resolve();
      }

      // This call is refreshing the token
      userAuthenticationService.getAuthorizationHeader();

      // Wait for the token to be refreshed
      const interval = setInterval(() => {
        if (userAuthenticationService.getUser()?.access_token) {
          clearInterval(interval);
          return resolve();
        }

        tries++;

        if (tries > 10) {
          clearInterval(interval);
          window.location.replace('/');
          reject();
        }
      }, 300);
    });
  }

  private async _fetchItemLabeledByUser(
    taskId: number,
    itemId: string,
    labeledBy: string
  ): Promise<LabelTaskItem> {
    const { userAuthenticationService } = this.servicesManager.services;

    await this.refreshToken();

    const currentItem = await fetchItemForLabeledBy(
      userAuthenticationService,
      taskId,
      itemId,
      labeledBy
    );

    const key = `${taskId}-${currentItem.id}`;
    await this._formatAndLoadItem(key, currentItem);

    this.fetchers.set(taskId, undefined);
    return this.getItem(key);
  }

  async fetchItemVersion(
    taskId: number,
    itemId: string,
    version?: number
  ): Promise<LabelTaskItem> {
    if (this.fetchers.get(taskId)) {
      return this.fetchers.get(taskId);
    }
    return this._fetchItemVersion(taskId, itemId, version);
  }

  private async _fetchItemVersion(
    taskId: number,
    itemId: string,
    version?: number
  ): Promise<LabelTaskItem> {
    const { userAuthenticationService } = this.servicesManager.services;

    const currentItem = await itemsApis.fetchItemVersion(
      userAuthenticationService,
      taskId,
      itemId,
      version
    );

    const key = `${taskId}-${currentItem.id}`;
    await this._formatAndLoadItem(key, currentItem);

    this.fetchers.set(taskId, undefined);
    return this.getItem(key);
  }

  private async _formatAndLoadItem(key: string, currentItem: LabelTaskApiItem) {
    const formattedItem = await formatItem(currentItem);
    this.currentItems.set(key, formattedItem);

    // TODO: tomo preloading is disabled for now as this may lead to memory issues
    // We need to check if some users are complaining about the performance of tomo loading.
    // const tomoImageIds = [];

    formattedItem.studies.forEach(study => {
      DicomMetadataStore.addStudy(study);
      const StudyInstanceUID = study.StudyInstanceUID;

      study.series
        .flatMap(series => series.instances)
        .forEach(instance => {
          const { url: imageId, metadata: naturalizedDicom } = instance;

          // Add imageId specific mapping to this data as the URL isn't necessarliy WADO-URI.
          metadataProvider.addImageIdToUIDs(imageId, {
            StudyInstanceUID,
            SeriesInstanceUID: naturalizedDicom.SeriesInstanceUID,
            SOPInstanceUID: naturalizedDicom.SOPInstanceUID,
          });

          // if (isTomo(naturalizedDicom)) {
          //   tomoImageIds.push(imageId);
          // }
        });
    });

    // tomoImageIds.forEach(preloadTomo);
    this.currentItems.set(key, formattedItem);

    this._broadcastEvent(this.EVENTS.UPDATE_CURRENT_ITEM, {
      currentItem: this.currentItems.get(key),
    });
  }
}
