import { Internal } from '@gleamer/types';
import { createSelector } from '@reduxjs/toolkit';
import { isEqual, omitBy, pick } from 'lodash';
import { TaskItemId, getTaskItemId } from '../../../utils';
import { RootState } from '../store';
import { getNextItemIds, getPreviousItemIds } from '../tasksSeenItems/tasksSeenItems.selectors';
import { ItemLabelPassState, LabelPassState, defaultLabelPassState } from './labelPass.slice';
import { loadFromCompressedLocalStorage } from './load-local-storage';

type ObservationSubmission = Internal.ObservationInternal;
type Region = Internal.RegionInternal;
type AssetCharacterisationSubmission = Internal.AssetCharacterisationInternal;

const selectLabelPassState = (state: RootState) => state.labelPassState.present;

export const getItemLabelPassState = createSelector(
  selectLabelPassState,
  getTaskItemId,
  (labelPassState, { taskId, itemId }) => {
    return labelPassState.data[taskId]?.[itemId] || defaultLabelPassState;
  }
);

export const getItemLabelPass = createSelector(getItemLabelPassState, state => {
  const { observations, characterisations, feedbacks, labeledBy } = state;

  return {
    observations,
    characterisations,
    feedbacks,
    labeledBy,
  };
});

export const getObservations = createSelector(getItemLabelPassState, state => state.observations);

export const getAssetCharacterisations = createSelector(
  getItemLabelPassState,
  state => state.characterisations
);

export const hasObservations = createSelector(
  getObservations,
  observations => observations.length > 0
);

export const getLastUpdatedObs = createSelector(
  getItemLabelPassState,
  observationsState => observationsState.lastUpdatedObs
);

export const getLastUpdatedObsUid = createSelector(
  getLastUpdatedObs,
  lastUpdatedbs => lastUpdatedbs?.uid
);

export const getLastUpdatedRegionUid = createSelector(
  selectLabelPassState,
  observationsState => observationsState.lastUpdatedRegionUID
);

export const getAllRegions = createSelector(getObservations, observations =>
  observations.flatMap(obs => obs.regions)
);

export const getAllRegionsUids = createSelector(getAllRegions, regions =>
  regions.map(region => region.uid)
);

export const getNextObsIndex = createSelector(
  getObservations,
  (_state, { obsCode, obsStatus }) => ({ obsCode, obsStatus }),
  (observations, { obsCode, obsStatus }) => {
    const obs = observations.find(obs => obs.code === obsCode && obs.status === obsStatus);
    return obs ? obs.index + 1 : 1;
  }
);

function getComparableRegion(region: Region) {
  return pick(region, [
    'toolName',
    'data.handles',
    'points',
    'SOPInstanceUID',
    'referenceSeriesUID',
    'referenceStudyUID',
  ]);
}

function getComparableObservation(observation: ObservationSubmission) {
  return {
    ...observation,
    regions: observation.regions.map(getComparableRegion),
  };
}

function getComparableObservations(observations: ObservationSubmission[]) {
  return observations.map(getComparableObservation);
}

function getComparableCharacterisations(
  characterisations: AssetCharacterisationSubmission[] = []
): AssetCharacterisationSubmission[] {
  return characterisations.map(characterisation => {
    return {
      ...characterisation,
      characterisation: omitBy(characterisation.characterisation, (_value, key) =>
        /^context\./.test(key)
      ),
    };
  });
}

function hasLabelPassChanged(itemLabelPassState: ItemLabelPassState) {
  const hasObservationsChanged = !isEqual(
    getComparableObservations(itemLabelPassState.observations),
    getComparableObservations(itemLabelPassState.initialObservations)
  );

  const hasCharacterisationsChanged = !isEqual(
    getComparableCharacterisations(itemLabelPassState.characterisations),
    getComparableCharacterisations(itemLabelPassState.initialCharacterisations)
  );

  return hasObservationsChanged || hasCharacterisationsChanged;
}

export const hasUnsavedChanges = createSelector(getItemLabelPassState, state =>
  hasLabelPassChanged(state)
);

function loadItemLabelPass({
  labelPassState,
  taskId,
  itemId,
}: TaskItemId & { labelPassState: LabelPassState }) {
  const itemLabelPassState = labelPassState.data[taskId]?.[itemId];
  if (itemLabelPassState) {
    return itemLabelPassState;
  }

  const key = `labelPass-${taskId}-${itemId}`;
  return loadFromCompressedLocalStorage(key);
}

export const getPreviousLabelPasses = createSelector(
  selectLabelPassState,
  getPreviousItemIds,
  getTaskItemId,
  (labelPassState, previousItemIds, { taskId }) => {
    return previousItemIds
      .map(itemId => loadItemLabelPass({ labelPassState, taskId, itemId }))
      .filter(Boolean);
  }
);

export const getNextLabelPasses = createSelector(
  selectLabelPassState,
  getNextItemIds,
  getTaskItemId,
  (labelPassState, nextItemIds, { taskId }) => {
    return nextItemIds
      .map(itemId => loadItemLabelPass({ labelPassState, taskId, itemId }))
      .filter(Boolean);
  }
);

export const hasPreviousUnsavedChanges = createSelector(
  getPreviousLabelPasses,
  previousLabelPasses => {
    return previousLabelPasses.some(hasLabelPassChanged);
  }
);

export const hasNextUnsavedChanges = createSelector(getNextLabelPasses, nextLabelPasses => {
  return nextLabelPasses.some(hasLabelPassChanged);
});

export const getFeedbacks = createSelector(getItemLabelPassState, state => state.feedbacks);
