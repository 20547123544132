import { RJSFSchema, UiSchema } from '@rjsf/utils';

import { CharacterisationInternalTypes } from '../characterisations';
import { Tool } from '../common/common.type';
import { AssetCharacterisationSpecificationsInternal } from '../characterisations/characterisation.internal.type';

export type RegionInternal = {
  source?: any;
  uid: string;
  SOPInstanceUID: string;
  FrameOfReferenceUID: string;
  points: [number, number, number][];
  metadata: any;
  referenceSeriesUID: string;
  referenceStudyUID: string;
  toolName: string;
  displaySetInstanceUID: string;
  label: string;
  displayText: string;
  data: any;
  type: string;
  getReport?: () => any;
  isActive?: boolean;
  characterisation?: CharacterisationInternalTypes.CharacterisationValuesInternal | null;
};

export const REGION_INTERNAL_KEYS: string[] = [
  'source',
  'uid',
  'SOPInstanceUID',
  'FrameOfReferenceUID',
  'points',
  'metadata',
  'referenceSeriesUID',
  'referenceStudyUID',
  'toolName',
  'displaySetInstanceUID',
  'label',
  'displayText',
  'data',
  'type',
  'getReport',
  'isActive',
  'characterisation',
];

// Copy from platform/core/src/services/MeasurementService/MeasurementService.ts
export const MEASUREMENT_SCHEMA_KEYS = [
  'uid',
  'data',
  'getReport',
  'displayText',
  'SOPInstanceUID',
  'FrameOfReferenceUID',
  'referenceStudyUID',
  'referenceSeriesUID',
  'frameNumber',
  'displaySetInstanceUID',
  'label',
  'description',
  'type',
  'unit',
  'points',
  'source',
  'toolName',
  'metadata',
  'area',
  'mean',
  'stdDev',
  'length',
  'shortestDiameter',
  'longestDiameter',
  'cachedStats',
  'selected',
  'textBox',
];

export type ObservationInternal = {
  uid: string;
  index: number;
  regions: RegionInternal[];
  code: string;
  status: string;
  color?: string;
  label?: string;
  characterisations: CharacterisationInternalTypes.CharacterisationValueInternal[];
  hidden?: boolean;
  author?: string;
  confidence?: number;
};

export type RegionSelectorInternal = {
  sopClassUIDs?: string[] | null;
  modalities?: string[] | null;
};

export type RegionSpecificationInternal = {
  tool: Tool;
  selector: RegionSelectorInternal | null;
  characterisation: {
    json: RJSFSchema;
    ui: UiSchema;
  } | null;
};

export type ObservationsSpecificationsInternal = {
  code: string;
  status: string;
  colors: string[];
  label: string;
  characterisations: AssetCharacterisationSpecificationsInternal | null;
  regions: RegionSpecificationInternal[];
};
