import { Enums, metaData } from '@cornerstonejs/core';
import { Internal, OHIF } from '@gleamer/types';
import { isReadonly, labelTask, tools } from '@gleamer/ui';
import { useViewportGrid } from '@ohif/ui';
import React, {
  KeyboardEventHandler,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMatchingRegion } from '../../../utils/region.utils';
import GleamerViewportOverlay from './GleamerViewportOverlay';
import { NextAnnotationTitle } from './NextAnnotationTitle';
import { ObservationSelector } from './ObservationSelector';
import { useNextObservation } from './useNextObservation';
import { useViewportTool } from './useViewportTool';

type ServicesManager = OHIF.ServicesManager;
type ObservationsSpecifications = Internal.ObservationsSpecifications;

type ViewportProps = {
  displaySets: any[];
  viewportIndex: number;
  dataSource: any;
  extensionManager: any;
  servicesManager: ServicesManager;
};

type GleamerViewportProps = PropsWithChildren<ViewportProps>;

function getTypes({
  obsSpecs,
  nextObs,
  currentSopClassUID,
  currentModality,
}: {
  obsSpecs: ObservationsSpecifications[];
  nextObs: { code: string; status: string };
  currentSopClassUID: string;
  currentModality: string;
}) {
  const matchingObs = obsSpecs.find(
    obs => obs.code === nextObs.code && obs.status === nextObs.status
  );
  if (!matchingObs) {
    return [];
  }

  const matchingRegion = matchingObs.regions.find(region =>
    isMatchingRegion(region, currentSopClassUID, currentModality)
  );
  if (!matchingRegion) {
    return [];
  }

  const { control } = matchingRegion.tool;
  if (!control) {
    return [];
  }

  const property = matchingRegion.characterisation?.json?.properties?.[control];
  if (!property) {
    return [];
  }

  return property.oneOf || [];
}

function GleamerViewport(props: GleamerViewportProps) {
  const { extensionManager, servicesManager, viewportId } = props;
  const { CornerstoneViewportService } = servicesManager.services;

  const { component: CornerstoneViewport } = extensionManager.getModuleEntry(
    '@ohif/extension-cornerstone.viewportModule.cornerstone'
  );

  const [{ activeViewportId }] = useViewportGrid();

  const [currentSopClassUID, setCurrentSopClassUID] = useState<string>('');
  const [currentModality, setCurrentModality] = useState<string>('');
  const [currentViewport, setViewport] = useState(null);

  const dispatch = useDispatch();
  const readOnly = isReadonly();
  const [showWheel, setShowWheel] = useState(false);
  const nextObs = useSelector(tools.selectors.getNextObs);
  const obsSpecs = useSelector(
    labelTask.selectors.getObservationsSpecifications
  );
  const singleObservationSpec = useSelector(
    labelTask.selectors.getOnlyObservation
  );

  const { selectNextObs } = useNextObservation({
    modality: currentModality,
    sopClassUID: currentSopClassUID,
  });

  const wrapperRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef(null);
  const wheelSelectorRef = useRef<HTMLDivElement>(null);
  const nextAnnotationRef = useRef<HTMLDivElement>(null);

  const isActiveViewport = activeViewportId === viewportId;
  const isAnnotableViewport = isActiveViewport && !readOnly;

  useViewportTool({
    viewportId,
    viewport: currentViewport,
    currentModality,
    currentSopClassUID,
  });

  const controls = useMemo(() => {
    if (!isAnnotableViewport) {
      return [];
    }
    return getTypes({
      obsSpecs,
      nextObs,
      currentSopClassUID,
      currentModality,
    });
  }, [
    currentModality,
    currentSopClassUID,
    isAnnotableViewport,
    nextObs,
    obsSpecs,
  ]);

  const elementRef = useRef(null);
  const [imageIndex, setImageIndex] = useState(null);

  const updateStackIndex = useCallback(event => {
    const { newImageIdIndex } = event.detail;
    // find the index of imageId in the imageIds
    setImageIndex(newImageIdIndex);
  }, []);

  const focusWrapper = useCallback(() => {
    wrapperRef.current?.focus();
  }, []);

  const blurWrapper = useCallback(() => {
    wrapperRef.current?.blur();
  }, []);

  const onMouseLeave: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    blurWrapper();
    if (nextAnnotationRef.current) {
      nextAnnotationRef.current.style.display = 'none';
    }
  }, [blurWrapper]);

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    e => {
      if (e.key === 'Tab') {
        e.preventDefault();
      }

      if (e.key === 'Shift') {
        if (!singleObservationSpec) {
          timeoutRef.current = setTimeout(() => {
            setShowWheel(true);
          }, 300);
        }
      }
    },
    [singleObservationSpec]
  );

  const onKeyUp: KeyboardEventHandler<HTMLDivElement> = useCallback(
    e => {
      if (e.key === 'Shift') {
        if (singleObservationSpec) {
          selectNextObs(singleObservationSpec);
        } else {
          setShowWheel(false);
          clearTimeout(timeoutRef.current);
        }
      }

      if (e.key === 'Tab') {
        e.preventDefault();
        dispatch(tools.actions.setNextControl());
      }
    },
    [dispatch, selectNextObs, singleObservationSpec]
  );

  useEffect(() => {
    if (!isAnnotableViewport) {
      return;
    }

    dispatch(tools.actions.setControls(controls));
  }, [controls, dispatch, isAnnotableViewport]);

  const onMouseMove: MouseEventHandler<HTMLDivElement> = useCallback(
    e => {
      if (readOnly) {
        return;
      }
      const wheelSelector = wheelSelectorRef.current;
      const nextRegion = nextAnnotationRef?.current;
      const x = e.pageX;
      const y = e.pageY;

      // Do not update positions while the selector is showing
      // In order the user to be able to choose an observation
      if (showWheel) {
        if (nextRegion) {
          nextRegion.style.display = 'none';
        }
        return;
      }

      // @ts-ignore: getBoundingClientRect exists
      const { top, left } = e.target.getBoundingClientRect();

      if (nextRegion) {
        nextRegion.style.display = 'block';
        nextRegion.style.top = y - top + 'px';
        nextRegion.style.left = x - left + 12 + 'px';
      }

      if (wheelSelector) {
        wheelSelector.style.top = y - top + 'px';
        wheelSelector.style.left = x - left + 12 + 'px';
      }
    },
    [readOnly, showWheel]
  );

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const getField = useCallback(
    (fieldGetter: (imageId: string) => string) => {
      if (activeViewportId !== viewportId) {
        return;
      }

      const viewport =
        CornerstoneViewportService.getCornerstoneViewport(viewportId);

      if (!viewport) {
        return;
      }

      let currentImageId: string;
      try {
        currentImageId = viewport.getCurrentImageId();
      } catch (e) {
        // This happens when volume viewxport is being loaded
        console.warn('Cannot get current image id');
        return null;
      }

      try {
        return fieldGetter(currentImageId);
      } catch (e) {
        return null;
      }
    },
    [CornerstoneViewportService, activeViewportId, viewportId]
  );

  const updateSopClassUID = useCallback(() => {
    const sopClassUID = getField(currentImageId => {
      const sopCommon = metaData.get('sopCommonModule', currentImageId);
      return sopCommon.sopClassUID;
    });

    setCurrentSopClassUID(sopClassUID);
  }, [getField]);

  const updateModality = useCallback(() => {
    const modality = getField(currentImageId => {
      const generalSeries = metaData.get('generalSeriesModule', currentImageId);
      return generalSeries.modality;
    });

    setCurrentModality(modality);
  }, [getField]);

  const onImageRendered = useCallback(() => {
    const viewport =
      CornerstoneViewportService.getCornerstoneViewport(viewportId);

    if (viewport) {
      setImageIndex(viewport.getCurrentImageIdIndex());
    }
  }, [CornerstoneViewportService, viewportId]);

  const onElementEnabled = useCallback(
    evt => {
      const { element } = evt.detail;
      elementRef.current = element;

      const viewport =
        CornerstoneViewportService.getCornerstoneViewport(viewportId);
      if (!currentViewport) {
        setViewport(viewport);
      }

      elementRef.current.addEventListener(
        Enums.Events.IMAGE_RENDERED,
        onImageRendered
      );

      if (isAnnotableViewport) {
        updateModality();
        updateSopClassUID();

        elementRef.current.addEventListener(
          Enums.Events.STACK_NEW_IMAGE,
          () => {
            updateModality();
            updateSopClassUID();
          }
        );
      }

      elementRef.current.addEventListener(
        Enums.Events.STACK_VIEWPORT_SCROLL,
        updateStackIndex
      );
    },
    [
      CornerstoneViewportService,
      currentViewport,
      isAnnotableViewport,
      onImageRendered,
      updateModality,
      updateSopClassUID,
      updateStackIndex,
      viewportId,
    ]
  );

  const onElementDisabled = useCallback(() => {
    if (!elementRef.current) {
      return;
    }

    elementRef.current.removeEventListener(
      Enums.Events.IMAGE_RENDERED,
      onImageRendered
    );

    if (isAnnotableViewport) {
      elementRef.current.removeEventListener(
        Enums.Events.STACK_NEW_IMAGE,
        () => {
          updateModality();
          updateSopClassUID();
        }
      );
    }

    elementRef.current.removeEventListener(
      Enums.Events.STACK_VIEWPORT_SCROLL,
      updateStackIndex
    );
  }, [
    isAnnotableViewport,
    onImageRendered,
    updateModality,
    updateSopClassUID,
    updateStackIndex,
  ]);

  useEffect(() => {
    if (!currentModality && isAnnotableViewport) {
      updateModality();
      updateSopClassUID();
    }
  }, [currentModality, isAnnotableViewport, updateModality, updateSopClassUID]);

  return (
    <div
      className="relative flex h-full w-full flex-row overflow-hidden"
      ref={wrapperRef}
      tabIndex={0}
      onKeyDown={isAnnotableViewport ? onKeyDown : null}
      onKeyUp={isAnnotableViewport ? onKeyUp : null}
      onMouseOver={isAnnotableViewport ? focusWrapper : null}
      onMouseLeave={isAnnotableViewport ? onMouseLeave : null}
      onMouseMove={isAnnotableViewport ? onMouseMove : null}
    >
      <CornerstoneViewport
        {...props}
        onElementEnabled={onElementEnabled}
        onElementDisabled={onElementDisabled}
      />
      <GleamerViewportOverlay {...props} imageIndex={imageIndex} />

      {isAnnotableViewport && (
        <>
          <ObservationSelector
            ref={wheelSelectorRef}
            style={{ display: showWheel ? 'block' : 'none' }}
            sopClassUID={currentSopClassUID}
            modality={currentModality}
          />
          <NextAnnotationTitle ref={nextAnnotationRef} />
        </>
      )}
    </div>
  );
}

export default GleamerViewport;
