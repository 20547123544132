import { DicomMetadataStore, ServicesManager, utils } from '@ohif/core';
import { useImageViewer, useViewportGrid } from '@ohif/ui';
import PropTypes from 'prop-types';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { StudyBrowser } from './StudyBrowser';

const { formatDate } = utils;

function PanelStudyBrowser({
  servicesManager,
  getImageSrc,
  getStudiesForPatientByStudyInstanceUID,
  requestDisplaySetCreationForStudy,
  dataSource,
}) {
  const {
    hangingProtocolService,
    displaySetService,
    measurementService,
    uiNotificationService,
  } = (servicesManager as ServicesManager).services;
  // Normally you nest the components so the tree isn't so deep, and the data
  // doesn't have to have such an intense shape. This works well enough for now.
  // Tabs --> Studies --> DisplaySets --> Thumbnails
  const { StudyInstanceUIDs = [] } = useImageViewer();
  const [{ activeViewportId, viewports }, viewportGridService] =
    useViewportGrid();
  const [expandedStudyInstanceUIDs, setExpandedStudyInstanceUIDs] = useState(
    []
  );
  const [studyDisplayList, setStudyDisplayList] = useState([]);
  const [displaySets, setDisplaySets] = useState([]);
  const [thumbnailImageSrcMap, setThumbnailImageSrcMap] = useState({});
  const isMounted = useRef(true);

  useEffect(() => {
    const subscriber = measurementService.subscribe(
      measurementService.EVENTS.JUMP_TO_MEASUREMENT_VIEWPORT,
      ({ measurement }) => {
        setExpandedStudyInstanceUIDs(alreadyExpanded => [
          ...alreadyExpanded,
          measurement.referenceStudyUID,
        ]);
      }
    );

    return () => {
      subscriber.unsubscribe();
    };
    // we don’t want to subscribe each time the component is rendered, but only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ~~ studyDisplayList
  useEffect(() => {
    // Fetch all studies for the patient in each primary study
    async function fetchStudiesForPatient(StudyInstanceUID) {
      const qidoStudiesForPatient =
        (await getStudiesForPatientByStudyInstanceUID(StudyInstanceUID)) || [];

      // TODO: This should be "naturalized DICOM JSON" studies
      const mappedStudies = _mapDataSourceStudies(qidoStudiesForPatient);

      const actuallyMappedStudies = mappedStudies.map(qidoStudy => {
        return {
          studyInstanceUid: qidoStudy.StudyInstanceUID,
          date: formatDate(qidoStudy.StudyDate),
          description: qidoStudy.StudyDescription,
          modalities: qidoStudy.ModalitiesInStudy,
          numInstances: qidoStudy.NumInstances,
          series: qidoStudy.series,
          // displaySets: []
        };
      });
      if (isMounted.current) {
        setStudyDisplayList(prevArray => {
          const ret = [...prevArray];
          for (const study of actuallyMappedStudies) {
            if (
              !prevArray.find(
                it => it.studyInstanceUid === study.studyInstanceUid
              )
            ) {
              ret.push(study);
            }
          }
          return ret;
        });
      }
    }

    StudyInstanceUIDs.forEach(sid => fetchStudiesForPatient(sid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StudyInstanceUIDs, getStudiesForPatientByStudyInstanceUID]);

  const onDoubleClickThumbnailHandler = (displaySetInstanceUID: string) => {
    let updatedViewports = [];
    try {
      updatedViewports = hangingProtocolService.getViewportsRequireUpdate(
        activeViewportId,
        displaySetInstanceUID
      );
    } catch (error) {
      console.warn(error);
      uiNotificationService.show({
        title: 'Thumbnail Double Click',
        message:
          'The selected display sets could not be added to the viewport.',
        type: 'info',
        duration: 3000,
      });
    }

    viewportGridService.setDisplaySetsForViewports(updatedViewports);
  };

  const onDoubleClickInstanceThumbnailHandler = (
    displaySetInstanceUID,
    sopInstanceUID,
    inverted
  ) => {
    const displaySetFromServ =
      displaySetService.getDisplaySetForSOPInstanceUID(sopInstanceUID);

    const imageIndex = displaySetFromServ?.images.findIndex(
      image => image.SOPInstanceUID === sopInstanceUID
    );

    const index = imageIndex >= 0 ? imageIndex : 0;

    viewportGridService.setDisplaySetsForViewport({
      viewportId: activeViewportId,
      viewportOptions: {
        initialImageOptions: {
          index: index,
        },
      },
      displaySetInstanceUIDs: [displaySetInstanceUID],
      displaySetOptions: [
        {
          voiInverted: inverted,
        },
      ],
    });
  };

  // ~~ displaySets
  useEffect(() => {
    // TODO: Are we sure `activeDisplaySets` will always be accurate?
    const currentDisplaySets = displaySetService.activeDisplaySets;
    const mappedDisplaySets = _mapDisplaySets(
      dataSource,
      currentDisplaySets,
      thumbnailImageSrcMap
    );

    setDisplaySets(mappedDisplaySets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnailImageSrcMap]);

  // ~~ thumbnails when a study is expanded
  useEffect(() => {
    const newDisplaySetsToDisplay = displaySets
      .filter(displaySet => {
        const { StudyInstanceUID } = displaySet;
        return expandedStudyInstanceUIDs.includes(StudyInstanceUID);
      })
      .filter(displaySet => {
        const { displaySetInstanceUID } = displaySet;
        return !Object.keys(thumbnailImageSrcMap).includes(
          displaySetInstanceUID
        );
      });

    const newImagesLoaders = newDisplaySetsToDisplay.map(async dSet => {
      const displaySet = displaySetService.getDisplaySetByUID(
        dSet.displaySetInstanceUID
      );
      const imageIds = dataSource.getImageIdsForDisplaySet(displaySet);
      const imageId = imageIds[Math.floor(imageIds.length / 2)];

      // TODO: Is it okay that imageIds are not returned here for SR displaysets?
      if (imageId) {
        const imageSrc = await getImageSrc(imageId, dSet.initialViewport);
        return {
          imageSrc,
          displaySetInstanceUID: dSet.displaySetInstanceUID,
        };
      }
      return null;
    });

    Promise.allSettled(newImagesLoaders).then(imageLoaders => {
      imageLoaders.forEach(source => {
        if (
          source.status === 'fulfilled' &&
          isMounted.current &&
          source.value
        ) {
          const { displaySetInstanceUID, imageSrc } = source.value;
          setThumbnailImageSrcMap(prevState => {
            return { ...prevState, [displaySetInstanceUID]: imageSrc };
          });
        }
      });
    });
  }, [expandedStudyInstanceUIDs]);

  // ~~ subscriptions --> displaySets
  useEffect(() => {
    // TODO: Will this always hold _all_ the displaySets we care about?
    // DISPLAY_SETS_CHANGED returns `DisplaySerService.activeDisplaySets`
    const SubscriptionDisplaySetsChanged = displaySetService.subscribe(
      displaySetService.EVENTS.DISPLAY_SETS_CHANGED,
      changedDisplaySets => {
        const mappedDisplaySets = _mapDisplaySets(
          dataSource,
          changedDisplaySets,
          thumbnailImageSrcMap
        );
        setDisplaySets(mappedDisplaySets);
      }
    );

    const SubscriptionDisplaySetAdded = displaySetService.subscribe(
      displaySetService.EVENTS.DISPLAY_SETS_ADDED,
      ({ displaySetsAdded }) => {
        const mappedDisplaySets = _mapDisplaySets(
          dataSource,
          displaySetsAdded,
          thumbnailImageSrcMap
        );
        setDisplaySets(displaySets => [...displaySets, ...mappedDisplaySets]);
      }
    );

    return () => {
      SubscriptionDisplaySetsChanged.unsubscribe();
      SubscriptionDisplaySetAdded.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const studies = _getStudiesToDisplay(
    StudyInstanceUIDs,
    studyDisplayList,
    displaySets,
    displaySetService,
    dataSource,
    thumbnailImageSrcMap
  );

  // TODO: Should not fire this on "close"
  function _handleStudyClick(StudyInstanceUID: string) {
    const shouldCollapseStudy =
      expandedStudyInstanceUIDs.includes(StudyInstanceUID);
    const updatedExpandedStudyInstanceUIDs = shouldCollapseStudy
      ? [
          ...expandedStudyInstanceUIDs.filter(
            stdyUid => stdyUid !== StudyInstanceUID
          ),
        ]
      : [...expandedStudyInstanceUIDs, StudyInstanceUID];

    setExpandedStudyInstanceUIDs(updatedExpandedStudyInstanceUIDs);

    if (!shouldCollapseStudy) {
      const madeInClient = true;
      requestDisplaySetCreationForStudy(
        displaySetService,
        StudyInstanceUID,
        madeInClient
      );
    }
  }

  function _fillViewportsOnStudyClick(
    event: SyntheticEvent<HTMLButtonElement>,
    StudyInstanceUID: string
  ) {
    event.stopPropagation();

    const activeStudy = DicomMetadataStore.getStudy(StudyInstanceUID);

    const currentDisplaySets = displaySetService.getActiveDisplaySets();

    hangingProtocolService.run({
      studies: [activeStudy],
      activeStudy,
      displaySets: currentDisplaySets,
    });
  }

  const activeDisplaySetInstanceUIDs =
    viewports.get(activeViewportId)?.displaySetInstanceUIDs;

  return (
    <StudyBrowser
      studies={studies}
      servicesManager={servicesManager}
      onDoubleClickThumbnail={onDoubleClickThumbnailHandler}
      onDoubleClickInstanceThumbnail={onDoubleClickInstanceThumbnailHandler}
      activeDisplaySetInstanceUIDs={activeDisplaySetInstanceUIDs}
      expandedStudyInstanceUIDs={expandedStudyInstanceUIDs}
      onClickStudy={_handleStudyClick}
      onFillViewports={_fillViewportsOnStudyClick}
      getImageSrc={getImageSrc}
    />
  );
}

PanelStudyBrowser.propTypes = {
  servicesManager: PropTypes.object.isRequired,
  dataSource: PropTypes.shape({
    getImageIdsForDisplaySet: PropTypes.func.isRequired,
  }).isRequired,
  getImageSrc: PropTypes.func.isRequired,
  requestDisplaySetCreationForStudy: PropTypes.func.isRequired,
};

export default PanelStudyBrowser;

/**
 * Maps from the DataSource's format to a naturalized object
 *
 * @param {*} studies
 */
function _mapDataSourceStudies(studies) {
  return studies.map(study => {
    // TODO: Why does the data source return in this format?
    return {
      AccessionNumber: study.accession,
      StudyDate: study.date,
      StudyDescription: study.description,
      NumInstances: study.instances,
      ModalitiesInStudy: study.modalities,
      PatientID: study.mrn,
      PatientName: study.patientName,
      StudyInstanceUID: study.studyInstanceUid,
      StudyTime: study.time,
      series: study.series,
    };
  });
}

function _mapDisplaySets(dataSource, displaySets, thumbnailImageSrcMap) {
  const thumbnailDisplaySets = [];
  const thumbnailNoImageDisplaySets = [];

  displaySets.forEach(ds => {
    const imageSrc = thumbnailImageSrcMap[ds.displaySetInstanceUID];
    const componentType = _getComponentType(ds.Modality);

    const array =
      componentType === 'thumbnail'
        ? thumbnailDisplaySets
        : thumbnailNoImageDisplaySets;

    const presentationLutShape = ds.images?.find(
      instance => instance.PresentationLUTShape
    )?.PresentationLUTShape;

    const photometricInterpretation = ds.images?.find(
      instance => instance.PhotometricInterpretation
    )?.PhotometricInterpretation;

    const inverted =
      presentationLutShape === 'INVERSE' ||
      photometricInterpretation === 'MONOCHROME1';

    const sopInstanceUIDs = ds.images.map(img => img.SOPInstanceUID);
    const imageIds = dataSource.getImageIdsForDisplaySet(ds);

    array.push({
      displaySetInstanceUID: ds.displaySetInstanceUID,
      description: ds.SeriesDescription || '',
      seriesNumber: ds.SeriesNumber,
      modality: ds.Modality,
      seriesDate: ds.SeriesDate,
      numInstances: ds.numImageFrames,
      StudyInstanceUID: ds.StudyInstanceUID,
      SeriesInstanceUID: ds.SeriesInstanceUID,
      SopInstanceUIDs: sopInstanceUIDs,
      imageIds: imageIds,
      inverted: inverted,
      componentType,
      imageSrc,
      dragData: {
        type: 'displayset',
        displaySetInstanceUID: ds.displaySetInstanceUID,
        inverted: inverted,
        imageIndex: 0,
        // .. Any other data to pass
      },
    });
  });

  return [...thumbnailDisplaySets, ...thumbnailNoImageDisplaySets];
}

const thumbnailNoImageModalities = [
  'SR',
  'SEG',
  'RTSTRUCT',
  'RTPLAN',
  'RTDOSE',
];

function _getComponentType(Modality) {
  if (thumbnailNoImageModalities.includes(Modality)) {
    // TODO probably others.
    return 'thumbnailNoImage';
  }

  return 'thumbnail';
}

function _getStudiesToDisplay(
  primaryStudyInstanceUIDs,
  studyDisplayList,
  displaySets,
  displaySetService,
  dataSource,
  thumbnailImageSrcMap
) {
  const primaryStudies = [];

  studyDisplayList.forEach(study => {
    if (!primaryStudyInstanceUIDs.includes(study.studyInstanceUid)) {
      return;
    }
    let displaySetsForStudy = displaySets.filter(
      ds => ds.StudyInstanceUID === study.studyInstanceUid
    );

    if (!displaySetsForStudy.length) {
      const displaySetsDuplicates = displaySetService.activeDisplaySets
        .filter(ds =>
          study.series.some(s => s.SeriesInstanceUID === ds.SeriesInstanceUID)
        )
        .map(ds => {
          const newDsId =
            ds.displaySetInstanceUID + `-${study.studyInstanceUid}`;
          return Object.assign({}, ds, {
            displaySetInstanceUID: newDsId,
            dragData: { ...ds.dragData, displaySetInstanceUID: newDsId },
            StudyInstanceUID: study.studyInstanceUid,
            images: study.series
              .find(s => s.SeriesInstanceUID === ds.SeriesInstanceUID)
              .instances.map(i => ({ ...i.metadata, url: i.url })),
          });
        });
      displaySetsDuplicates.forEach(ds => displaySetService.addDisplaySets(ds));
      displaySetsForStudy = _mapDisplaySets(
        dataSource,
        displaySetsDuplicates,
        thumbnailImageSrcMap
      );
    }

    const tabStudy = Object.assign({}, study, {
      displaySets: displaySetsForStudy,
    });

    primaryStudies.push(tabStudy);
  });

  return primaryStudies;
}
