import { annotation } from '@cornerstonejs/tools';
import { Internal, OHIF } from '@gleamer/types';
import { Accordion, labelPass, useTask } from '@gleamer/ui';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { EyeIcon, EyeSlashIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useViewportGrid } from '@ohif/ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { isValidObservation } from '../../../../../utils/validation.utils';
import { ObservationCharacterisation } from './ObservationCharacterisation';
import { RegionList } from './RegionList';
import ObservationFalseNegative from './ObservationFalseNegative';

const { deleteObservation, deleteRegion, toggleObservationVisibility } =
  labelPass.actions;

type ServicesManager = OHIF.ServicesManager;
type ObservationSubmission = Internal.ObservationInternal;

type ObservationTableProps = {
  servicesManager: ServicesManager;
  observation: ObservationSubmission;
  index: number;
  open?: boolean;
  onObservationClick: () => void;
};

function useSyncWithOpen(open: boolean) {
  const [currentlyOpened, setCurrentlyOpened] = useState<boolean>(open);

  useEffect(() => {
    setCurrentlyOpened(open);
  }, [open]);

  const toggle = useCallback(
    () => setCurrentlyOpened(wasOpened => !wasOpened),
    []
  );

  return [currentlyOpened, toggle] as const;
}

export default function ObservationItem({
  servicesManager,
  observation,
  index,
  open = false,
  onObservationClick,
}: ObservationTableProps) {
  const { MeasurementService, CornerstoneViewportService } =
    servicesManager.services;

  // @ts-ignore: Bad OHIF typing
  const [viewportGrid] = useViewportGrid();
  const { activeViewportId, viewports } = viewportGrid;

  const { t } = useTranslation();
  const task = useTask();
  const dispatch = useDispatch();

  const [openRegions, toggleRegions] = useSyncWithOpen(open);

  // Task is loaded asynchronously, wait for it to be retrieved
  if (!task?.observations) {
    return null;
  }

  const obsSpec = task.observations.find(
    obs => obs.code === observation.code && obs.status === observation.status
  );

  if (!obsSpec) {
    return null;
  }

  const { label, colors } = obsSpec;
  const color = colors[index % colors.length];

  const isValid = isValidObservation(task, observation);

  const jumpToMeasurement = (viewportIndex, regionUID) => {
    MeasurementService.jumpToMeasurement(viewportIndex, regionUID);
    //If we don't need to jump instances then the region won't be selected
    annotation.selection.setAnnotationSelected(regionUID);
  };

  const onRegionHover = ({ region }) => {
    annotation.selection
      .getAnnotationsSelected()
      .forEach(annotationUID =>
        annotation.selection.deselectAnnotation(annotationUID)
      );

    annotation.selection.setAnnotationSelected(region.uid);
  };

  const onFocusRegion = ({ region }) => {
    const measurement = MeasurementService.getMeasurement(region.uid);
    const referencedDisplaySetInstanceUID = measurement.displaySetInstanceUID;

    // Get all viewports that already contains the region
    const matchingViewportsIndexes: {
      viewportId: string;
      matches: boolean | undefined;
    }[] = [];

    for (const [viewportId, viewport] of viewports.entries()) {
      matchingViewportsIndexes.push({
        viewportId,
        matches: viewport.displaySetInstanceUIDs?.includes(
          referencedDisplaySetInstanceUID
        ),
      });
    }

    // If no viewport contains the region, display it in the active viewport
    if (matchingViewportsIndexes.every(({ matches }) => !matches)) {
      jumpToMeasurement(activeViewportId, region.uid);
      return;
    }

    // Find all viewports that contains the region.
    const exactMatches = matchingViewportsIndexes
      .filter(match => match.matches)
      .map(({ viewportId }) => {
        const viewport =
          CornerstoneViewportService.getCornerstoneViewport(viewportId);

        if (!viewport) {
          console.log(`Viewport ${viewportId} is not exact`);
          return {
            viewportId,
            isExact: false,
          };
        }

        return {
          viewportId,
          isExact:
            viewport.getCurrentImageIdIndex() ===
            viewport
              .getImageIds()
              .findIndex(uid => region.metadata.referencedImageId === uid),
        };
      });

    // If at least one viewport is already displaying the region,
    // use the first viewport index that displays it
    const firstMatchingExactly = exactMatches.find(({ isExact }) => isExact);
    if (firstMatchingExactly) {
      jumpToMeasurement(firstMatchingExactly.viewportId, region.uid);
      return;
    }

    // If the active viewport contains the region, use the active viewport
    if (
      matchingViewportsIndexes.some(
        match => match.viewportId === activeViewportId && match.matches
      )
    ) {
      jumpToMeasurement(activeViewportId, region.uid);
      return;
    }

    // Use the first viewport that contain the region
    jumpToMeasurement(
      matchingViewportsIndexes.find(({ matches }) => matches).viewportId,
      region.uid
    );
  };

  const onRegionMouseLeave = ({ region }) => {
    annotation.selection.deselectAnnotation(region.uid);
  };

  const onDeleteRegion = ({ region }) => {
    dispatch(deleteRegion(region.uid));
  };

  const onDeleteObservation = () => {
    dispatch(deleteObservation(observation.uid));
  };

  const onToggleObservationVisibility = () => {
    dispatch(toggleObservationVisibility(observation.uid));
  };

  return (
    <Accordion open={open} onClick={onObservationClick}>
      <div
        className="z-9 sticky top-0 flex items-center justify-between py-1 text-white"
        style={{ backgroundColor: color }}
      >
        <Accordion.Title>
          <h4 className="flex w-32 items-center justify-between break-all pr-1 text-sm font-medium">
            {label} {index}
            {isValid ? (
              <CheckBadgeIcon className="inline h-5 w-5 min-w-5 pl-1" />
            ) : (
              <span className="inline-block h-5 w-5 pl-1"></span>
            )}
          </h4>
        </Accordion.Title>
        <ObservationFalseNegative
          className="mr-1 flex items-center text-sm font-medium"
          confidence={observation?.confidence}
          status={observation?.status}
          falseNegativeThreshold={task.falseNegativeThreshold}
        />
        <div className="mr-2 flex items-center">
          <button
            className="mr-2"
            title={t('Hide observation regions')}
            onClick={onToggleObservationVisibility}
          >
            {observation.hidden ? (
              <EyeSlashIcon className="h-4 w-4" />
            ) : (
              <EyeIcon className="h-4 w-4" />
            )}
          </button>
          <button title={t('Delete')} onClick={onDeleteObservation}>
            <TrashIcon className="h-4 w-4" />
          </button>
        </div>
      </div>
      <Accordion.Content>
        <div className="invisible-scrollbar overflow-y-auto overflow-x-hidden pl-2">
          <ObservationCharacterisation
            observation={observation}
            servicesManager={servicesManager}
          />
          <hr className="my-2 border-gray-700" />
          <Accordion open={openRegions} onClick={toggleRegions}>
            <div className="flex justify-between py-1 text-white">
              <Accordion.Title>
                <h5 className="text-sm font-medium">Regions</h5>
              </Accordion.Title>
            </div>
            <Accordion.Content>
              <div className="invisible-scrollbar overflow-y-auto overflow-x-hidden">
                <RegionList
                  observation={observation}
                  onFocusRegion={onFocusRegion}
                  onRegionHover={onRegionHover}
                  onRegionMouseLeave={onRegionMouseLeave}
                  onDelete={onDeleteRegion}
                  open={open}
                  servicesManager={servicesManager}
                />
              </div>
            </Accordion.Content>
          </Accordion>
        </div>
      </Accordion.Content>
    </Accordion>
  );
}
