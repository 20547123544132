import React, { CSSProperties, useMemo } from 'react';
import { Tooltip } from '@ohif/ui';

type FalseNegativeStatus = 'FN' | 'TP' | 'NA';

const OBSERVATION_LABELS: Record<FalseNegativeStatus, string> = {
  FN: 'False Negative',
  TP: 'True Positive',
  NA: 'Not Available',
};

const BASE_STYLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  width: 30,
  height: 20,
  boxSizing: 'border-box',
};

export default function ObservationFalseNegative({
  confidence,
  status: observationStatus,
  falseNegativeThreshold,
  className,
}: {
  confidence?: number;
  status?: string;
  falseNegativeThreshold?: number;
  className?: string;
}) {
  const { style, falseNegativeStatus } = useMemo(() => {
    if (
      falseNegativeThreshold === undefined ||
      falseNegativeThreshold === null ||
      observationStatus !== 'POSITIVE'
    ) {
      return {
        style: BASE_STYLE,
        falseNegativeStatus: '',
      };
    }
    let status = 'NA';
    if (confidence !== undefined && confidence !== null) {
      status = confidence < falseNegativeThreshold ? 'FN' : 'TP';
    }
    return {
      falseNegativeStatus: status,
      style: (status === 'FN'
        ? {
            ...BASE_STYLE,
            borderWidth: 2,
            borderColor: 'black',
            backgroundColor: 'red',
          }
        : BASE_STYLE) as CSSProperties,
    };
  }, [confidence, falseNegativeThreshold, observationStatus]);

  return falseNegativeStatus ? (
    <Tooltip
      position="left"
      content={<div>{OBSERVATION_LABELS[falseNegativeStatus]}</div>}
    >
      <span className={className} style={style}>
        {falseNegativeStatus}
      </span>
    </Tooltip>
  ) : (
    <span className={className} style={style} />
  );
}
